import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  MessageSeparator,
  ConversationHeader,
  Avatar,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
} from "@chatscope/chat-ui-kit-react";
import RobotIcon from "./robot.png";
import { useEffect, useState } from "react";
import axios from "axios";

export default function App() {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([
    {
      message:
        "Welcome to DuitTogether Chat! Ask us anything about your business data.",
      sentTime: "just now",
      sender: "DuitTogether",
      direction: "incoming",
    },
  ]);

  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  useEffect(() => {}, []);

  async function handleSend(innerHTML, textContent, innerText, nodes) {
    setLoading(true);
    const newMessage = {
      message: innerHTML,
      sentTime: "just now",
      sender: "You",
      direction: "outgoing",
    };
    setMessages((oldMessages) => [...oldMessages, newMessage]);
    const response = await axios.post(
      "https://api.openai.com/v1/completions",
      {
        model: "text-davinci-003",
        prompt: `Expenditure Breakdown:\nPayroll: 10.3%\nSupplies: 89.7%\n\nSales Performance Breakdown by Product:\nFried Noodles: 43.2%\nFried Rice: 2.3%\nNasi Lemak: 54.5%\n\nNet Sales by period:\nFebruary: $2000\nMarch: $1000\nApril: $3500\nMay: $2500\nJune: $3500\n\nGiven the above data for a certain company, the following question is answered in a way that is descriptive and relevant for the business owner to better understand the state of their business. The response below is strictly relevant and ties in closely to the above data.\n\nQuestion: ${innerHTML}\nAnswer:\n \n`,
        temperature: 1,
        max_tokens: 128,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    const answer = response.data.choices[0].text;
    setMessages((oldMessages) => [
      ...oldMessages,
      {
        message: answer,
        sentTime: "just now",
        sender: "DuitTogether",
        direction: "incoming",
      },
    ]);
    setLoading(false);
  }

  return (
    <div style={{ position: "relative", height: "500px" }}>
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Back />
            <Avatar src={RobotIcon} name="DuitTogether Chat" />
            <ConversationHeader.Content
              userName="DuitTogether Chat"
              info="Active now"
            />
            <ConversationHeader.Actions>
              <VoiceCallButton />
              <VideoCallButton />
              <InfoButton />
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            typingIndicator={
              loading ? (
                <TypingIndicator content="DuitTogether is typing..." />
              ) : (
                ""
              )
            }
          >
            <MessageSeparator content="DuitTogether Chat" />
            {messages.map((model, index) =>
              model.direction === "incoming" ? (
                <Message key={index} model={model}>
                  <Avatar src={RobotIcon} name="DuitTogether" />
                </Message>
              ) : (
                <Message key={index} model={model} />
              )
            )}
          </MessageList>
          <MessageInput
            placeholder={loading ? "Loading..." : "Type question here..."}
            onSend={handleSend}
            disabled={loading}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
